<template>
  <div class="file-upload-container">
    <h2>File Upload</h2>
    <!-- Upload  -->
      <input id="file-upload" type="file" @change="handleFileUpload" />

      <label for="file-upload" id="file-drag" :class="{ hover: isDragging }" @dragover.prevent="fileDragHover" @dragleave="fileDragHover" @drop.prevent="handleFileSelect">
        <img
          v-if="fileImage"
          :src="fileImage"
          alt="Preview"
          class="file-image"
        />
        <div id="start" :class="{ hidden: isFileSelected }">
          <i class="fa fa-download" aria-hidden="true"></i>
          <div>Select a file or drag here</div>
          <span id="file-upload-btn" class="btn btn-primary">Select a file</span>
        </div>
      </label>
  </div>
</template>

<script>

export default {
  data() {
    return {
      uploadingFile: false,
      pendingTTS: '',
    };
  },
  methods: {
    async setCurrentThought(vectorStoreId) {
      this.vectorStoreId = vectorStoreId;
      await this.handleFileUpload();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];

      // Ensure the file is not undefined
      if (!file) {
        console.error('No file uploaded');
        return;
      }

      if (file.type.match(/^image\/(png|jpeg|gif)$/)) {
        this.imageFile = file;
        const imageUrl = URL.createObjectURL(file);
        this.imagePreviews.push({ type: 'image', url: imageUrl });
        console.log('This file is an image. Proceed with the operations.');
      } else {
        this.uploadingFile = true;
        this.imageFile = file;
        this.updateMemoryOfThread(file);
        this.imageFile = null;
      }
      // only if its not jpg/png etc
    },

    async updateMemoryOfThread(file) {
      console.log('vector is being updated. Proceed with the operations.');
      console.log('storeid', this.vectorStoreId);
      if (!this.vectorStoreId) return;

      const formData = new FormData();
      formData.append('vectorStoreId', this.vectorStoreId);
      formData.append('doc', file);

      try {
        const response = await fetch(
          `${process.env.VUE_APP_AXIOS_URI}/eddie/smart-vector-update`,
          {
            method: 'POST',
            headers: {
              // No Content-Type header needed for FormData
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
            body: formData,
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            `HTTP error! Status: ${response.status}, Message: ${errorData.message}`,
          );
        }

        const data = await response.json();
        console.log('Success:', data);
        this.uploadingFile = false;
        this.imageFile = null;
        this.imagePreviews = [];
      } catch (err) {
        console.error('Error:', err.message);
      }
    },
  },
};
</script>

<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto');

.file-upload-container {
  text-align: center;
  padding: 2rem;
  background: #f8f8f8;
}

.file-upload-container h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  line-height: 1;
  color: #454cad;
  margin-bottom: 0;
}

.file-upload-container p {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: #5f6982;
}

.uploader {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
  max-width: 600px;
}

.uploader label {
  display: block;
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #eee;
  transition: all 0.2s ease;
  user-select: none;
}

.uploader label.hover {
  border-color: #454cad;
  box-shadow: inset 0 0 0 6px #eee;
}

#start {
  width: 100%;
}

#start.hidden {
  display: none;
}

#response {
  width: 100%;
}

#response.hidden {
  display: none;
}

.file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  width: auto;
  height: auto;
  max-width: 180px;
}

progress,
.progress {
  display: inline;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  height: 8px;
  border: 0;
  border-radius: 4px;
  background-color: #eee;
  overflow: hidden;
}

.progress[value]::-webkit-progress-bar {
  border-radius: 4px;
  background-color: #eee;
}

.progress[value]::-webkit-progress-value {
  background: linear-gradient(to right, darken(#454cad, 8%) 0%, #454cad 50%);
  border-radius: 4px;
}

input[type="file"] {
  display: none;
}

.btn {
  display: inline-block;
  margin: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: initial;
  border: none;
  border-radius: 0.2rem;
  padding: 0 1rem;
  height: 36px;
  line-height: 36px;
  color: #fff;
  background: #454cad;
  cursor: pointer;
}
</style>
